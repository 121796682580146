import React from 'react';
import {Link} from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../../../assets/images/logos/sangen_logo_white.svg';
import {APPROVAL_NUMBER} from "../../../constants";
import Logo from "../../Logo";

const y = new Date().getFullYear();

const Footer = () => (
  <footer>
    <Container>
      <Row style={{padding:"20px 0"}}>
        <Col sm={12} md={12} lg={3}>
          <Link to="/"><div className="logo" /></Link>
        </Col>
        <Col sm={12} md={12} lg={3}>
            <ul>
                <li><Link to="/gebruiksvoorwaarden">Gebruiksvoorwaarden</Link></li>
                <li><a href="https://www.sanofi.nl/nl/data-privacy">Data Privacy</a></li>
                <li><Link to="/cookies">Cookieverklaring </Link></li>
                <li><a href="https://www.sanofi.nl/nl/contact">Contact </a></li>
                <li><Link to="/sitemap">Sitemap </Link></li>
            </ul>
        </Col>
        <Col sm={12} md={12} lg={6} style={{padding:"10px 0"}}>
          <div><a href="https://www.sanofi.nl" target="_blank"><Logo white={true}/></a>
              <br/>
            <p>Copyright©{y} Sanofi B.V. Alle rechten voorbehouden.</p>
            <p>Deze website is bedoeld voor zorgverleners in Nederland.</p>
            <p>{APPROVAL_NUMBER}</p>
            <p><br/></p>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);


export default Footer;
