import * as React from "react";
import {motion, useCycle} from "framer-motion";
import {MenuToggle} from "./MenuToggle";
import {Navigation} from "./Navigation";
import Container from 'react-bootstrap/Container';
import {Link, navigate} from "@reach/router";

const nav = {
  open: {
    zIndex: 9,
    height: '100%'
  },
  closed: {
    zIndex: 0,
    height: 0,
    transition: {
      delay: 0.6, }
  }
};

const sidebar = {
  open: (height = 1000) => ({
    // clipPath: `circle(${height * 2 + 200}px at 95% 0px)`,
    height: "100%",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 40
    },
  }),
  closed: {
    // clipPath: `circle(30px at 110% 0px)`,
    height: '0%',
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 600,
      damping: 40
    },
  }
};

export const MobileMenu = ({data}) => {

  const [isOpen, toggleOpen] = useCycle(false, true);

  return data && (
    <div className="header-mobile" id={isOpen ? "dark" : ""}>
      <Container>

       <Link to={'/'}>
         <div className="logo" />
       </Link>

        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          variants={nav}
        >
          <motion.div className="background" variants={sidebar} />
          <Navigation data={data} />
          <MenuToggle toggle={() => toggleOpen()} />
        </motion.nav>
      </Container>
    </div>
  );
};
