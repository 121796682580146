import React, {Component} from 'react';
import {Link} from "gatsby";

class Breadcrumbs extends Component {

    constructor(props) {

        super(props);
        this.state = {
            pathNames: null
        };

    }

    componentDidMount(){
        let {location} = this.props;
        let pathNames = location.pathname.split("/").filter((el)=> {return el.length !== 0});
        this.setState({pathNames:pathNames});
    }

    render() {

        const {seperator, data} = this.props;

        let breadcrumbs = data;

        return(
            breadcrumbs && breadcrumbs.length >= 1 &&
                <ul>
                    <li><Link to={'/'}>{'Home'}</Link><span>{seperator}</span></li>
                {
                     breadcrumbs.map((item, i)=>{

                        let crumb = JSON.parse(item);

                        return (
                            <li key={i} className={(item.name == crumb.name && (breadcrumbs.length-1) == i) ? 'active' : ''}>
                                <Link to={crumb.path}>{crumb.menu}</Link>
                                {(i !== (breadcrumbs.length-1)) ? <span>{seperator}</span> : ' ' }
                            </li>
                        );
                    })
                }
                </ul>

        )

    }

}

export default Breadcrumbs;
