import React, {useEffect, useRef, useState} from "react";
import Container from 'react-bootstrap/Container';

const GoUpArrow = () => {
  const ref = useRef(null);
  const [isAtTop, setTop] = useState(false);

  const getBodyScrollTop  = () => {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop
  };

  const handleScroll = () => {
    if(ref.current) {
      setTop(getBodyScrollTop() >= 600);
    }
  };


  useEffect(() => {

    if(typeof window !== 'undefined') {
        window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if(typeof window !== 'undefined') {
          window.removeEventListener('scroll', () => handleScroll);
      }
    };
  }, []);

  return (
    <div ref={ref}>
      {isAtTop && (
        <div className="go-arrow-up" onClick={()=> window.scrollTo({ top: 0, behavior: "smooth" })} >
          <span />
        </div>
      )}
    </div>
  )

}


export default GoUpArrow;
