import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function handleCookiesAccepted() {
    if (typeof window !== 'undefined') {
        localStorage.setItem('cookiesAccepted', 'true');
    }
}

function injectOptanonWrapperScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = 'function OptanonWrapper() { }';
    document.body.appendChild(script);
}

/**
 * OTScript
 * OneTrust Cookiebar implementation
 * @returns {JSX.Element|null}
 * @constructor
 */
function OTScript({ dataDomainScript = '' }) {
    useEffect(() => {
        // Check if the user has already accepted the cookies
        const hasAcceptedCookies = localStorage.getItem('cookiesAccepted') === 'true';

        if (!hasAcceptedCookies) {
            // Remove existing script tag if present
            const existingScript = document.getElementById('OTScript');
            if (existingScript) {
                existingScript.remove();
            }

            // Create and inject new script tag
            const script = document.createElement('script');
            script.id = 'OTScript';
            script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
            script.setAttribute('data-domain-script', dataDomainScript);
            script.charset = 'UTF-8';
            script.type = 'text/javascript';

            const head = document.querySelector('html > head');
            head.insertBefore(script, head.firstChild);

            script.addEventListener('load', () => {
                // Once the script is loaded, add the event listener for the user's acceptance
                const onetrustAcceptBtnHandler = document.getElementById('onetrust-accept-btn-handler');
                if (onetrustAcceptBtnHandler) {
                    onetrustAcceptBtnHandler.addEventListener('click', handleCookiesAccepted);
                    handleCookiesAccepted();
                }
            });
        }
    }, [dataDomainScript]);

    useEffect(() => {
        // Inject OptanonWrapper script before </body>
        injectOptanonWrapperScript();
    }, []);

    return null;
}

OTScript.propTypes = {
    /** id */
    dataDomainScript: PropTypes.string,
};

export default OTScript;
