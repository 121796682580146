import React, {Component} from 'react';
import styled from 'styled-components';
import Portal from "../Portal";
import {Modal} from "react-bootstrap";

const PopupWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  z-index: 9999;
`

const Popup = styled.div`
  margin: auto;
  background-color: white;
  padding: 30px;
`

const Button = styled.a`
  margin-right: 20px;
  margin-top: 10px;
  display: inline-block;
  color: white !important;
  text-decoration: none;
  background-color: rgb(30, 26, 74);
  padding: 5px 10px;
  transition: 200ms all ease-out;
  &:hover {
    color: white;
    -webkit-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.75);
  }
`

let link = '';


// withExternalLinkPopup is Higher Order Component that wraps up the Base Layout and
// listens to clicks where the anchors lead to external websites.
const withExternalLinkPopup = (PassedComponent) =>
  class WithExternalLinkPopup extends Component {

    constructor(props) {
      super(props);


      this.state = {
        showExternalLinkPopup: false,
      };

      this.pageClick = this.pageClick.bind(this);
      this.togglePopup = this.togglePopup.bind(this);
      this.openUrlInNewTab = this.openUrlInNewTab.bind(this);
    }

    togglePopup = () => {
      const currentState = this.state.showExternalLinkPopup;
      this.setState({ showExternalLinkPopup: !currentState });
    };

    openUrlInNewTab = (url) => {
        if (typeof window !== 'undefined') {
            let win = window.open(url, '_blank');
            win.focus();
        }
      this.setState({ showExternalLinkPopup: false });
    };

    pageClick = (e) => {

        this.setState({ showExternalLinkPopup: false });

        if(typeof e.target !== 'undefined' || e.target !== null){

            let target = e.target || e.currentTarget();
            let host = new RegExp("//" + window.location.host + "($|/)");
            let apiUrl = process.env.GATSBY_API_URL.replace(/^https?\:\/\//i, "");
            let apiHost = new RegExp("//" + apiUrl + "($|/)");

            let href = (target.getAttribute('href')!==null)? target.getAttribute('href') : (target.parentNode)? target.parentNode.getAttribute('href') : "";
            let local =  (href !== null && href.substring(0, 4) === "http") ? host.test(href) : true;
            link = href;

            if(!local && !apiHost.test(href)){
                e.preventDefault();
                this.togglePopup();
                return true;
            }
        }
        return false;
    };

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener('click', this.pageClick, false);
        }
    }
    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('click', this.pageClick, false);
        }
    }

    render() {
      return (
        <PassedComponent {...this.props}>
          {this.state.showExternalLinkPopup &&
          <Modal
              centered
              show={this.state.showExternalLinkPopup}
              onHide={()=>{
                  this.setState({showExternalLinkPopup:false});
              }}
              dialogClassName="modal-90w"
              aria-labelledby="diseases-modal"
          >
              <Modal.Header closeButton>
                  <Modal.Title id="diseases-modal">
                      U verlaat de website
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <p>U staat op het punt lyso.nl te verlaten.<br/>Sanofi is niet verantwoordelijk voor de inhoud van deze externe website, uitgezonderd vervolg websites in eigendom van Sanofi.</p>
                  <Button onClick={()=>this.openUrlInNewTab(link)}>Verder</Button>
                  <Button onClick={(e)=>{
                      e.preventDefault();
                      this.setState({showExternalLinkPopup:false});
                  }}>Terug</Button>
              </Modal.Body>
          </Modal>}
          {this.props.children}
        </PassedComponent>
      )
    }
  }

export default withExternalLinkPopup
