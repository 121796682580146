import * as React from "react";
import {motion} from "framer-motion";
import {Link} from 'gatsby';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = ({ title, route }) => {
  return (
    <motion.li
      variants={variants}
    >
      <Link to={route}>{title}</Link>
    </motion.li>
  );
};
