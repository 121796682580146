import React, {Component} from 'react';
import {navigate} from 'gatsby';

import {Link} from 'gatsby';

import Container from 'react-bootstrap/Container';
import {TweenLite} from "gsap/TweenLite";
import {Expo} from 'gsap';
import StickComponent from "../StickComponent";
import PropTypes from "prop-types";

const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
        ? { style: { fontWeight: "600" } }
        : null
};

const isPartiallyActiveSubmenu = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
        ? { className: 'submenu-active' }
        : null
};

// DESKTOP NAVIGATION
class Navigation extends Component {
    constructor(props){

        super(props);

        this.state = {
            didMount:false,
            indicatorWidth:0,
            lastPositionX:0,
            toPositionX:0
        };

        this.indicator = null;
    }

    componentDidMount(){

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', ()=>{

                const navWrapper = document.getElementsByClassName('nav-bottom');
                const activeItem = document.getElementsByClassName('submenu-active');
                const indicator = document.getElementsByClassName('nav-indicator');

                if(activeItem.length > 0 && navWrapper.length > 0) {

                    const leftOffset = activeItem[0].getBoundingClientRect().left - navWrapper[0].getBoundingClientRect().left;

                    indicator[0].style.left = leftOffset + "px";
                }



            });
        }
        this.animateIndicator();
    }

    animateIndicator() {

        const navWrapper = document.getElementsByClassName('nav-bottom');
        const activeItem = document.getElementsByClassName('submenu-active');
        const indicator = document.getElementsByClassName('nav-indicator');

        if(activeItem.length <= 0 || indicator.length <= 0) return;

        const leftOffset = activeItem[0].getBoundingClientRect().left - navWrapper[0].getBoundingClientRect().left;

        TweenLite.fromTo(this.indicator, 1, {
                css: {left: `${(this.state.lastPositionX===0) ? leftOffset:this.state.lastPositionX}`, width: `${(this.state.indicatorWidth===0) ? activeItem[0].getBoundingClientRect().width:this.state.indicatorWidth}`},
                ease: Expo.easeInOut,
                onComplete: () => {
                }
            },
            {
                css: {left: `${leftOffset}`, width:`${activeItem[0].getBoundingClientRect().width}`},
                ease: Expo.easeInOut,
                onComplete: () => {
                }
            }
        );

    }

    onClick(evt, route){

        evt.preventDefault();

        sessionStorage.removeItem("currentItemId");
        sessionStorage.removeItem("currentItemUri");

        const navWrapper = document.getElementsByClassName('nav-bottom');
        const activeItem = document.getElementsByClassName('submenu-active');
        const element = evt.currentTarget.getBoundingClientRect();

        const leftOffsetFrom = activeItem[0].getBoundingClientRect().left - navWrapper[0].getBoundingClientRect().left;
        const leftOffsetTo = element.left - navWrapper[0].getBoundingClientRect().left;

        TweenLite.fromTo(this.indicator, 0.4, {
                css: {left: `${leftOffsetFrom}`, width: `${activeItem[0].getBoundingClientRect().width}`},
                ease: Expo.easeInOut,
                onComplete: () => {
                }
            },
            {
                css: {left: `${leftOffsetTo}`, width: `${element.width}`},
                ease: Expo.easeInOut,
                onComplete: () => {
                    this.setState({
                        lastPositionX: leftOffsetTo,
                        indicatorWidth: element.width,
                        didMount:true
                    });
                    navigate(route);
                }
            }
        );

    }
    render() {

        const { data, showSubNav } = this.props;
        let header = (data.page.route !== '/') ? data.page.header : [{ body_classes: 'no-submenu' }];

        // @todo: check why home doesnt get header?

        return (
            <nav className="nav">
                <Container className="nav-wrapper">
                    <div className={`nav-top`}>
                    { data && data.page.route !== '/' &&
                        <a href="/" rel="home" className="site-logo">
                            <div className={'logo'} />
                        </a>
                    }
                    <ul className="list-main">
                        {data && data.pages.map((page, k) => {
                        return page.visible && page.route !== '/' +
                            '' && <li key={k} style={{ paddingLeft: 20 }} >
                            <Link to={page.route} getProps={isPartiallyActive}>
                            {page.title}
                            </Link>
                        </li>
                        })}
                    </ul>
                    </div>
                    <div className={`nav-bottom`}>
                        <StickComponent
                            canBeSticky={data.page.route !== '/' && data.page.header && data.page.header[0].body_classes !== 'no-submenu'}
                            topOffset={0}
                            scrollOffset={71}
                            onStickStart={()=>{
                                let contentWrap = document.querySelector(".content-wrap");
                                contentWrap.style.transform = "none";
                            }}
                        >
                        {data && data.page.route !== '/' && data.page.header && data.page.header[0].body_classes !== 'no-submenu' &&
                            <div className="nav-indicator" ref={div => this.indicator = div}/>
                        }
                        {data && data.page.route !== '/' && <ul className="list-secondary">
                            {data && header && header.body_classes !== 'no-submenu' && data.pages.map(page => {
                                return page.children && page.children.map((child, n) => {
                                        return ((child.header && child.header[0].body_classes !== 'no-submenu' && showSubNav) ?
                                            <li key={n}>
                                                <Link to={child.route} onClick={(evt)=>this.onClick(evt, child.route)} getProps={isPartiallyActiveSubmenu}>
                                                    {child.title}
                                                </Link>
                                            </li> : null
                                        );
                                    })
                            })}
                            </ul>
                        }
                        </StickComponent>
                    </div>
                </Container>
            </nav>
        )
    }
}
Navigation.defaultProps = {
    showSubNav: false,
    data: null
};
Navigation.propTypes = {
    showSubNav: PropTypes.bool,
    data: PropTypes.object.isRequired
};

export default Navigation;
