
import React, {Component} from 'react';
import {Transition} from 'react-transition-group';
import {TweenMax} from 'gsap';
import Container from 'react-bootstrap/Container';
// STYLING
import 'bootstrap/dist/css/bootstrap.min.css';

import '../../../style/index.scss';

import SiteMetadata from '../../SiteMetaData';
import Navigation from '../../Navigation';
import Footer from '../Footer';
import CookieBar from '../CookieBar';
import {MobileMenu} from '../../MobileNavigation/index';
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import GoUpArrow from "../../GoUpArrow";
import withExternalLinkPopup from "../../ExternalLinkPopup";
import AddToHomeScreen from "../../AddToHomeScreen";
import Logo from "../../Logo";
import OTScript from "../../../lib/otscript";
const startState = { autoAlpha: 0, y: 0 };

class BaseLayout extends Component {

    componentDidMount() {

        let selectHTMLtag = document.getElementsByTagName("html")[0];

        // if the browser is IE add class to html tag
        if(!!navigator.userAgent.match(/Trident.*rv\:11\./) || navigator.userAgent.indexOf('MSIE')!==-1) {
            selectHTMLtag.className = "ie"
        }

        // listen to external links
        let links = document.getElementsByTagName("a");

        // @todo: purpose of this function?
        // for (let i = 0, length = links.length; i < length; i++) {
        //     let anchor = links[i];
        //     anchor.addEventListener('click', function() {
        //         // `this` refers to the anchor tag that's been clicked
        //     }, true);
        // }
    }

    render() {

        const {show, data, location, children, showSubNav} = this.props;
        let hasAcceptedCookies = null;
        if (typeof window !== 'undefined') {
            hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
        }

        return (
            <div className="base content-container">
                    <SiteMetadata data={data.page}/>
                    <noscript>
                    {` 
                        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PFW5L69N"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>
                    `}
                    </noscript> 
                    {/* HEADER DESKTOP */}
                    {data.page.route === "/" && <AddToHomeScreen/> }
                    <div className="header">
                        <div className="container header-top">
                            <div className="header-top-right">
                                <a href="https://www.sanofi.nl"><Logo/></a>
                            </div>
                        </div>
                        <Navigation data={data} showSubNav={showSubNav}/>
                    </div>
                    {/* HEADER MOBILE */}
                    <div className="nav-mobile">
                        <MobileMenu data={data}/>
                    </div>
                    <Transition
                        exit={true}
                        in={show}
                        timeout={800}
                        onEnter={node => TweenMax.set(node, startState)}
                        onExit={el => console.log("exit", el)}
                        addEndListener={ (node, done) => {
                            TweenMax.to(node, 0.75, {
                                autoAlpha: show ? 1 : 0,
                                top: show ? 0 : 100,
                                onComplete: done
                            });
                        }}
                    >
                        <div className="main-layout content-wrap">
                            <Container>
                                <div className="breadcrumbs">
                                    { data.page.breadcrumbs && <Breadcrumbs data={data.page.breadcrumbs} location={location} seperator={"/"}/> }
                                </div>
                                {children}
                                <GoUpArrow />
                            </Container>
                        </div>
                    </Transition>
                    <Footer />
                    {/*<CookieBar />*/}
                    { (typeof window !== 'undefined' && !hasAcceptedCookies) && <OTScript dataDomainScript={'018dfbaf-5ccc-7b74-9096-43f37ed9dce2'}/> }
            </div>
        )
    }
}

export default withExternalLinkPopup(BaseLayout);
