/**
 * custom sticky component for navigation
 * @author joost
 */

import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const StickComponent = props => {

    const [isSticky, setSticky] = useState(false);
    const [newWidth, setNewWidth] = useState(-1);

    const ref = useRef(this);

    const onStickStart = props.onStickStart;
    const onStickEnd = props.onStickEnd;

    let stickStarted = false;

    const getBodyScrollTop  = () => {
        const el = document.scrollingElement || document.documentElement;
        return el.scrollTop
    };

    const handleScroll = () => {
        if(ref.current) {
            setSticky(getBodyScrollTop() >= props.scrollOffset && props.canBeSticky);
            if(getBodyScrollTop() >= props.scrollOffset && props.canBeSticky) {
                if(onStickStart!==null) onStickStart();
                stickStarted = true;
                setNewWidth(ReactDOM.findDOMNode(ref.current).parentNode.getBoundingClientRect().width);
            } else if(stickStarted) {
                if(onStickEnd!==null) onStickEnd();
                stickStarted = false;
                setNewWidth(-1);
            }
        }
    };

    const handleResize = () => {
        if(ref.current) {
            setNewWidth(ReactDOM.findDOMNode(ref.current).parentNode.getBoundingClientRect().width);
        }
    };

    useEffect(() => {
        if(typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleResize);
        }
        handleResize();
        return () => {
            if(typeof window !== 'undefined') {
                window.removeEventListener('scroll', () => handleScroll);
                window.removeEventListener('resize', () => handleResize);
            }
        };
    }, []);

    return (
        <div ref={ref} className={isSticky ? 'sticky-nav' : ''} style={{top:props.topOffset, width:newWidth ? newWidth : 'auto'}}>
            <div className={'sticky-inner'}>
            {props.children}
            </div>
        </div>
    );
};

StickComponent.defaultProps = {
    onStickStart: null,
    onStickEnd: null
};
StickComponent.propTypes = {
    canBeSticky: PropTypes.bool.isRequired,
    topOffset: PropTypes.number.isRequired,
    scrollOffset: PropTypes.number.isRequired,
    onStickStart: PropTypes.func,
    onStickEnd: PropTypes.func
};
export default StickComponent;
