import * as React from "react";
import {motion} from "framer-motion";
import {MenuItem} from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.03, delayChildren: 0.14, type: "spring" },
    visibility: 'visible'
  },
  closed: {
    transition: { staggerChildren: 0.02, delay: 0.2, staggerDirection: -1, type: "spring" },
    visibility: 'hidden',
  }
};

export const Navigation = ({data}) => {

  return (

    <motion.ul variants={variants}>
      {
        data && data.pages.length !== 0 && data.pages.map((child, i) => {
          return child.visible && child.title !== 'Home' && (
            <React.Fragment key={i}>
              <MenuItem title={child.title} route={child.route}/>
              {child.children.length !== 0 && child.header[0].body_classes !== "no-submenu" &&
              (
                <span title={child.title} className="sub-menu">
                  {child.children.map(child => (
                    <MenuItem key={child.title} title={child.title} route={child.route}/>
                  ))}
                 </span>
              )}
            </React.Fragment>

          )
        })
      }
    </motion.ul>
  );
}
