import React, {Component} from "react"
import {Helmet} from "react-helmet"

class SiteMetadata extends Component {

    render() {

        const {data} = this.props;
        return (
            <Helmet>
                <title>{data && data.title} | Lyso.nl</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"/>
                {data.metadata && data.metadata.map((metaTag, key)=>{
                    return <meta key={key} name={metaTag.name} content={metaTag.content} />;
                })}
                <meta property="og:site_name" content={`Lyso.nl`} />
                <meta property="og:title" content={`${data.title}`} />
                {data.metadata && data.metadata.map((metaTag, key)=>{
                    if(metaTag.name === 'description'){
                        return <meta key={key} property="og:description" content={`${metaTag.content}`} />;
                    }
                })}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://www.lyso.nl${data.route}`} />
                <meta property="og:image" content={`https://www.lyso.nl/lyso.png`} />
                <meta name="google-site-verification" content="CUBCPSe-S1QVCBhyYSNdy6lRhw1lYXTpPwPrM9AZ4yc" />
                <script>
                    {` 
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-PFW5L69N');
                    `}
                </script>
                <script type="application/ld+json">
                    {` {
                            "@context" : "https://schema.org",
                            "@type": "MedicalWebPage",
                            "audience": "https://health-lifesci.schema.org/MedicalResearcher",
                            "specialty": "https://schema.org/Genetic",
                            "url": "https://www.lyso.nl/",
                            "description" : "Complete en betrouwbare informatie over alle lysosomale stapelingsziekten"
                        }
                    `}
                </script>
                <html lang="nl" />
            </Helmet>
        )
    }
}
export default SiteMetadata;
