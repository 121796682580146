import React, { Component } from 'react';

const iOSShareBase64 = 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAActpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx4bXA6Q3JlYXRvclRvb2w+QWRvYmUgSW1hZ2VSZWFkeTwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KKS7NPQAAAsxJREFUSA2lVjFrFFEQnnm7l2iiF2LuEggWImchFjb5B4KFhZ3+AC1io1hZJedyYGNjERANaB9FG0FBBGuLIBamukYQRXN4GnKSy+6+cebt283buz3dmIF3O2/ezHwz82ZnD+BfdIm8VKW+vLsmK92Dc5bJ9sUEpFL9+nL4bPYOkSzhUzk4OpnMYdDh86wYBqjFQU1HrxGoDoA9qzRBAJ2O8s+nOuaZ92B2xQBECIh0JqCxbzp6yUpnp7r+8a1j8WMADdUfn678mj7xmUE+zCn/wscAd8HaDGJkJRg8kH0XvvqI8A6VPtdewT6LjrCfo+2VU32RyVkXwC+yHSHjyCUSWQW1rTej57KGjEU3tQO2dchBlwMk2DvmCpQkc1eurvXFogTgCbfiZYxrSzsXUal7DBQDmPaMEPiOgR51WpVbLMsCqjXDuwrwKoHuAChuZdJiQ6Budlr4AqzPxOANyF3EgF6DL/ckkb7GNpMQx2I0gYjvJT5OjvcJcRe8JaKfnOZv8FCB1j1E7wEQNIyG9ZkAzLNYCBUh6Y3N1thDsx/4YaUMYLPlv+JjWRnNNsMbnEGytz6zlFMtjuqw4blF4UsC3BgHJV1Ezg01rtN4u28B5zk5blVju3eHxk0egDRnbrO5DaG8C6LVDpLac6mSTEU2wyUNMDJeTNcxJ7biw6E8gHNQxHKEOeMiHSmzK98XACobsethkD9gBtOD/ob2/5mB6R6llPsWZx01BOIIypXITtXvAd43tumkdRyNYssBiLWApB+YQN70clQeQPw9Le84hf/ruE6VDvLMZ2A6wLbx4rrPA6vURcLiugQa8tAbiiUPwOORh1ZS39WFEFaH9EcJEhsSW/4MOZQDQB2LQnVmiU77GFYjz44Nx6CI9XkOR1TZ4t8qT4sEzCrmAFi2rSb9OdyBDVIVyP6vFHl1ZPLl8LiYeMgH3Qu3nSP4A9EHFXrGnVaNAAAAAElFTkSuQmCC';
const closeBase64 = 'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAAl0lEQVR4Ae2TMQ6DMBTFfA+kHqkSUmcKgd5/BIaEqsOfWqyILgx4jewl/3FxSm74q8ODlcQeL1Za769sFNKOXthYaBAS5Wci9ExH4AlG143xKxH6k0oiMbluTEQi9B5BEpW6fNtHH+CfQCEd13PcxVF9IHnC9V6us1qvTsjVScJ1SeicM53MbKZBaFlkcYmZO473aTghF2/X9XbQ39L36gAAAABJRU5ErkJggg=='

class AddToHomeScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: (typeof navigator !== 'undefined') ? navigator.standalone === false : false,
            height:0,
            showed:false
        };
    }

    componentDidMount() {

        this.setState({
            height:(typeof window !== 'undefined') ? window.innerHeight - 150 : 100
        });

        if(typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll);
        }

    }

    handleClose = () => {

        this.setState({
            visible: false
        });
        if(typeof window !== 'undefined') sessionStorage.setItem("lyso-nl_prompt_webapp", "true");
    };
    getBodyScrollTop  = () => {
        const el = document.scrollingElement || document.documentElement;
        return el.scrollTop
    };

    handleScroll = () => {
        this.handleClose();
    };

    render() {
        const { height } = this.state;

        let showPrompt = false;

        if(typeof window !== 'undefined') {
            showPrompt = sessionStorage.getItem("lyso-nl_prompt_webapp") === "true"
        }

        return (
            <div
                style={{
                    position: 'absolute',
                    top: `${height}px`,
                    zIndex:999,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: this.state.visible && !showPrompt ? 'inline-block' : 'none',
                    color: '#333'
                }}
            >
                <div
                    style={{
                        height: 120,
                        padding: 10,
                        paddingTop: 20,
                        background: '#fff',
                        width: '300px',
                        border: '1px solid #666',
                        borderRadius:'10px',
                        lineHeight: '25px',
                        fontSize: '14px',
                        fontFamily: '-apple-system',
                    }}
                >
                    Zet deze website op de homescherm van uw telefoon, druk op:
                    <img alt="img" style={{ verticalAlign: 'top' }} width={'25px'} height={'25px'} src={`data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 25 25' style='enable-background:new 0 0 25 25;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23007AFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M15.6,5c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.1-0.3l-2.8-2.8c-0.2-0.2-0.5-0.2-0.7,0L9.4,4.4 C9.3,4.5,9.2,4.6,9.2,4.7c0,0.1,0.1,0.2,0.2,0.3h0C9.6,5.1,9.8,5.1,10,5l2-2v10.8c0,0.3,0.2,0.5,0.5,0.5S13,14,13,13.8V2.9l2,2 c0.1,0.1,0.2,0.1,0.3,0.1C15.4,5.1,15.5,5.1,15.6,5L15.6,5'/%3E%3Cpath class='st0' d='M18,7h-2.5C15.2,7,15,7.2,15,7.5S15.2,8,15.5,8h2.6C18.6,8,19,8.4,19,8.9v10.2c0,0.5-0.4,0.9-0.9,0.9H6.9 C6.4,20,6,19.6,6,19.1V8.9C6,8.4,6.4,8,6.9,8h2.6c0.1,0,0.3-0.1,0.4-0.2C9.9,7.8,10,7.6,10,7.5c0-0.1-0.1-0.3-0.1-0.3 C9.8,7.1,9.6,7,9.5,7H7C5.9,7,5,7.9,5,9v10c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2V9C20,7.9,19.1,7,18,7'/%3E%3C/g%3E%3C/svg%3E%0A`}/>
                    <br/>
                    {' '}en kies dan voor "Zet op beginscherm".
                </div>
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderLeft: '20px solid transparent',
                        borderRight: '20px solid transparent',
                        borderTop: '20px solid #666',
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                />
                <div
                    style={{
                        width: 0,
                        height: 0,
                        borderLeft: '20px solid transparent',
                        borderRight: '20px solid transparent',
                        borderTop: '20px solid #fff',
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bottom: '-19px'
                    }}
                />
                <img
                    alt="img"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: 25,
                        height: 25,
                        padding: 5,
                        cursor: 'pointer'
                    }}
                    src={`data:image/png;base64,${closeBase64}`}
                    onClick={this.handleClose}
                />
            </div>
        );
    }
}

export default AddToHomeScreen;
